import React from "react";

// Components
import { Link } from "gatsby";
import SEO from "../shared/SEO";

// Style
import styled from "styled-components";
import { neuzeitGroLight, primary, fontSize } from "../../styles/fonts";

const SummaryContainer = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.lg};
  cursor: pointer;
`;

// Parse this into a readable format
const Date = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.xs};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover {
    color: ${primary};
  }
`;

const AllPosts = ({ data }) => {
  // filter all posts and about
  const visiblePosts = data.allMdx.edges.filter(node => {
    return node.node.frontmatter.title !== "allposts";
  });

  // add markup
  return visiblePosts.map(node => {
    const summary = node.node.frontmatter;

    return (
      <>
        <SEO content={{ title: "ALL POSTS", excerpt: "All NPRZ.IO Posts" }} />
        <SummaryContainer>
          <StyledLink to={summary.path}>
            <Title>{summary.title}</Title>
          </StyledLink>
          <Date>{summary.date}</Date>
        </SummaryContainer>
      </>
    );
  });
};

export const query = graphql`
  query AllPostsQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            title
            date
            path
          }
        }
      }
    }
  }
`;

export default AllPosts;
